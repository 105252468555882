import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProductList from '../components/Products/ProductList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import { GlobalCta } from '../components/Acf/GlobalCta'
import { Accreditations } from '../components/Accreditations';
import { FeatureList } from '../components/Acf/FeatureList'
import { Image } from '../components/Image'
import './product-category.scss';

const Category = (props) => {
  const { data, pageContext, location } = props
  const { postsOnPage, categoryFilter, categories, thisCategory, site, yoast = [], wordpressWpSettings } = data
  const { wordpressUrl } = wordpressWpSettings
  const { edges: posts, totalCount } = postsOnPage
  const { title: siteTitle } = wordpressWpSettings
  const { name: category, slug, pathPrefix } = pageContext
  const title = `${category} Range`
  const { acf, description } = thisCategory
  return (
    <Layout className={`page-wrap`} wordpressUrl={wordpressUrl} location={location}>
      <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast}
        location={location}
      />
      <PageHeader headerTitle={title} headerSubTitle={description} headerBackgroundImage="" location={location} above={acf.above_honeycomb} below={acf.below_honeycomb} />
      {acf.categoryPageDescription && acf.title && 
      <section className="intro default third">
        <div className="wrap">
          <div className="inner">
            <div className="title">
              <h3 className="section-title" dangerouslySetInnerHTML={{__html: acf.title}} />
            </div>
            <div className="content">
              <div className="entry-content" dangerouslySetInnerHTML={{__html: acf.categoryPageDescription}} />
            </div>
          </div>
        </div>
      </section>
      }
      {acf.categoryFeatures && acf.categoryFeatures.features && <FeatureList features={acf.categoryFeatures.features}/>}
      {acf.categoryHeaderImage && <div className="category-big-image"><Image src={acf.categoryHeaderImage} /></div>}
      <ProductList
        posts={posts}
        title={category}
        pageContext={pageContext}
        categories={categories.edges}
        siteMetadata={wordpressWpSettings}
        pathPrefix={pathPrefix}
        location={location}
      />
      <Accreditations category={pageContext.category_id} title={category}/>
      <GlobalCta links={acf.links}/>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query ProductCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    postsOnPage: allWordpressWpProducts(
      filter: {product_category: {elemMatch: {wordpress_id: {in: $category_id}}}}
      sort: {order: ASC, fields: menu_order}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProductListFields
        }
      }
    }
    thisCategory: wordpressWpProductCategory(wordpress_id: {in: $category_id}) {
      description
      acf {
        title
        categoryPageDescription
        categoryFeatures {
          features {
            title
          }
        }
        links {
          link {
            target
            title
            url
          }
          title
          subTitle
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 520, quality: 70) {
                  ... GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        categoryHeaderImage {
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        below_honeycomb {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ... GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        above_honeycomb {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ... GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    categories: allWordpressWpProductCategory {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
