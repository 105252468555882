import React from 'react';
import './FeatureList.scss';

export const FeatureList = (props) => {
  const {
    features
  } = props
  return (
    <section className="feature-list">
      <div className="wrap">
        <ul>
          {features && features.map((feature, index) =>
            <li key={index} className="feature">{feature.title}</li>
          )}
        </ul>
      </div>
    </section>
  )
}
